v
<template>
  <v-sheet class="customer" id="customer" style="height: calc(100vh - 140px)">
    <v-row>
      <v-col md="9" class="my-auto d-flex">
        <h1 class="custom-header-blue-text m-0 ml-4">Segments</h1>
        <template v-if="summary.length">
          <div class="d-flex ml-4" v-for="(item, index) of summary" :key="index">
            <v-chip v-if="item?.value == 'all'"
              style="text-transform: capitalize"
              label
              class="ml-2"
              :text-color="item.textcolor"
              :color="item.color"
            >
              {{ item?.text }}: {{ item.total_rows }}</v-chip
            >
            <v-chip v-else
              style="text-transform: capitalize"
              label
              class="ml-2"
              :text-color="item.textcolor"
              :color="item.color"
            >
              {{ item?.text }}: {{ item.segment_status_count }}</v-chip
            >
          </div>
        </template>
      </v-col>
      <v-col md="3" class="text-right d-flex justify-end">
        <v-menu offset-y left transition="slide-y-transition" :close-on-content-click="false" >
              <template v-slot:activator="{ on: menu, attrs }">
                <v-tooltip left>
                  <template v-slot:activator="{ on: tooltip }">
                    <v-btn  color="blue darken-4" depressed tile v-bind="attrs" v-on="{ ...tooltip, ...menu }"
                      class="white--text mr-4 mt-1">
                      <v-icon>edit_note</v-icon>
                    </v-btn>
                  </template>
                  <span>Columns arrangement and visibility</span>
                </v-tooltip>
              </template>
              <v-list nav dense>
                <v-list-item draggable @dragstart="dragstart(index)" @dragover="dragover(index)" @dragend="dragEnd"
                  v-for="(item, index) in defaultColDefs" :key="item.field + '_' + index"
                  class="flex-column column-arrangement-list-item cursor-move">
                  <div class="d-flex flex-row">
                    <template v-if="!item.checkbox || Number(item.checkbox) == 0">
                      <v-checkbox :ripple="false" hide-details color="#0d47a1" v-model="defaultColShow"
                        v-bind:value="item.field" :disabled="Number(item.fixed) || pageLoading"
                        v-on:change="updateVisible()" class="m-0"></v-checkbox>

                      <v-list-item-title>{{ item.headerName }}</v-list-item-title>
                      <v-icon>drag_indicator</v-icon>
                    </template>
                  </div>
                  <v-divider class="mt-1 mb-0" style="width: 100%"></v-divider>
                </v-list-item>
              </v-list>
            </v-menu>
        <v-btn
          :disabled="pageLoading"
          :loading="pageLoading"
          class="white--text mt-1"
          depressed
          color="blue darken-4"
          tile
          v-on:click="generateSegmentDialog = true"
        >
          Create Segment
        </v-btn>
        
      </v-col>
    </v-row>
    <v-row>
      <v-col md="12">
        <v-simple-table fixed-header class="bt-table table_height">
          <template v-slot:default>
            <thead>
              <tr>
                <th v-for="heading in customThead" :key="heading.title" style="background-color: #f5f5f5">
                  <div>{{ heading.headerName }}</div>
                </th>
              </tr>
            </thead>
            <tbody v-if="!pageLoading">
              <!-- v-if="employeeList.length" -->
              <!-- {{ employeeList }} -->
              <template v-if="segmentList.length">
                <tr v-for="(row, bkey) in segmentList" :key="bkey" :class="bkey % 2 === 0 && 'blue lighten-5'"
                  class="employee-listing-tr" link @click="goToSegmentDetail(row.id)">
                  <td v-for="(th, index) in customThead" :key="'key_' + index">
                    <div v-if="th.field === 'id'"></div>

                    <div v-else-if="th.field === 'actions'">
                     
                      <v-tooltip top content-class="custom-top-tooltip">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            depressed
                            fab
                            dark
                            x-small
                            color="red"
                            v-bind="attrs"
                            v-on="on"
                            v-on:click.stop.prevent="deleteConfirm(row.name, row.id)"
                          >
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </template>
                        <span>Delete</span>
                      </v-tooltip>
                    </div>
                    <div v-else-if="th.field === 'name'"  class="cursor-pointer">
                      <ValueTemplate :title="'name'" :value="row.name"></ValueTemplate>

                    </div>
                    <div v-else-if="th.field === 'total'"  class="cursor-pointer">
                      <v-chip class="ma-2" color="teal" text-color="white" label>
                        {{ row.total_contacts }}
                        <!-- <ValueTemplate :title="'contact'"  :value="row.total_contacts"></ValueTemplate> -->
                      </v-chip>
                    </div>

                    <div v-else-if="th.field === 'filter'" 
                      class="cursor-pointer">
                      <template v-for="(filter, index) in row.filters">
                      <Chip
                        v-if="filter.name"
                        small
                        class="mr-2 mb-2"
                        style="text-transform: uppercase"
                        :text="filter.placeholder"
                        :key="index"
                      ></Chip>
                      </template>
                    </div>

                  
                    <div v-else-if="th.field === 'used_time'" 
                      class="cursor-pointer">
                      <ValueTemplate :title="'used time'" :value="row.total_time_used"></ValueTemplate>
                    </div>
                    <div v-else-if="th.field === 'email'" 
                      class="cursor-pointer">
                      <v-chip color="#0D47A1" outlined>
                        <ValueTemplate :title="'Email'" :value="row.email"></ValueTemplate>
                      </v-chip>
                    </div>
                    <div v-else-if="th.field === 'job_type'"  class="cursor-pointer">
                      <v-chip color="#0D47A1" outlined>
                        <ValueTemplate style="text-transform: uppercase;" :title="' job type'" :value="row.role_name"></ValueTemplate>
                        
                      </v-chip>
                    </div>
                    <div v-else-if="th.field === 'status'"
                      class="cursor-pointer">
                     <v-switch
                      v-on:click.stop.prevent="changeSegmentStatus(row.activated, row.id)"
                      color="blue darken-4"
                      v-model="row.activated"
                    ></v-switch>
                    </div>
                    <div v-else-if="th.field === 'address'" 
                      class="cursor-pointer">
                      <ValueTemplate :title="'address'" :value="row.address">
                      </ValueTemplate>
                    </div>
                    <div v-else-if="th.field === 'country'"  class="cursor-pointer">
                      <ValueTemplate :title="'no after probation period'" :value="row.country"></ValueTemplate>
                    </div>
                    <div v-else-if="th.field === 'created_at' && th.status" link
                  v-on:click="routeToDetailSupplier(row)">
                  <p class="my-auto py-2">
                    <v-chip class="py-0" small outlined color="primary" label>
                      <ValueTemplate :value="row?.added_by?.display_name" title="Admin"></ValueTemplate>
                      <!-- {{ row[getColValue(th.title)]?.value[0].createdBy_admin }} -->
                    </v-chip>
                  </p>
                  <p>
                    <v-chip small label style="font-weight: 600">
                      <!-- <ValueTemplate :value="row.formatDate" title="Created Date"></ValueTemplate> -->
                      {{ formatedateTimes(row.added_at) }}
                      <!-- {{ row[getColValue(th.title)]?.value[0].created_date }} -->
                    </v-chip>
                  </p>
                </div>
                <div v-else-if="th.field === 'updated_at' && th.status" link
                  v-on:click="routeToDetailSupplier(row)">
                  <template v-if="row.updated_at">
                    <!-- {{ formatedateTimes(row.updated_at) }} -->
                    <!-- <p class="my-auto py-2">
                      <v-chip class="py-0" small outlined color="primary" label>
                        <ValueTemplate :value="row[getColValue(th.title)]?.value[0].createdBy_admin"
                          title="Created by admin"></ValueTemplate>
                      </v-chip>
                    </p> -->
                    <p>
                      <v-chip small label style="font-weight: 600">
                        {{ formatedateTimes(row.updated_at) }}
                        <!-- {{ row[getColValue(th.title)]?.value[0].created_date }} -->
                      </v-chip>
                    </p>
                  </template>
                  <template v-else>
                    <p style="font-size: 16px; font-weight: 600">no modified</p>
                  </template>
                </div>
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td colspan="9">
                    <p class="m-0 text-center">
                      <img width="30" :src="$assetURL('media/error/empty.png')" class="row-not-found-image mr-4" />
                      Uhh... There are no employee at the moment.
                    </p>
                  </td>
                </tr>
              </template>
            </tbody>
            <tfoot v-else>
              <tr v-for="idr in 9" :key="`_$skeleton_$_locader_${idr}`">
                <td v-for="idk in 9" :key="idk">
                  <v-skeleton-loader type="text"></v-skeleton-loader>
                </td>
              </tr>
            </tfoot>
          </template>
        </v-simple-table>
        <template>
          <v-layout v-if="segmentList.length" class="light-border-top mt-4">
            <v-flex md6>
              <label class="btx-label p-4">{{ showingString }}</label>
            </v-flex>
            <v-flex md6>
              <v-pagination
                color="blue darken-4"
                v-model="currentPage"
                :length="totalPages"
                :total-visible="7"
                v-on:input="getSegments()"
              ></v-pagination>
            </v-flex>
          </v-layout>
        </template>
      </v-col>
    </v-row>

    <CreateSegment
      v-if="generateSegmentDialog"
      :dialog="generateSegmentDialog"
      @close="generateSegmentDialog = false"
      @success="getSegments()"
    />
    <Dialog :dialog.sync="deleteDialog" :dialogWidth="750">
      <template v-slot:title> Delete Segments</template>
      <template v-slot:body>
        <v-row class="delete-dialog">
          <v-col md="2" class="py-0 text-right my-auto">
            <span class="svg-icon svg-icon-lg delete-confirm-icon">
              <!--begin::Svg Icon-->
              <img :src="$assetURL('media/custom-svg/attention-circle.svg')" />              <!--end::Svg Icon-->
            </span>
          </v-col>
          <v-col md="10" class="py-0 my-auto">
            <p class="btx-p m-0">
              <span class="text-capitalize">Segments </span>
              <b class="red--text text--lighten-1">{{ deleteText }}</b> will be deleted forever and
              cannot be retrieved later.<br />Are you sure about deleting it?
            </p>
          </v-col>
          <v-col md="12" class="pb-0 my-auto">
            <em>Note: All transactions of this segment will also be deleted.</em>
          </v-col>
        </v-row>
      </template>
      <template v-slot:action>
        <v-btn
          class="white--text"
          :loading="pageLoading"
          :disabled="pageLoading"
          depressed
          color="red lighten-1"
          tile
          v-on:click="deleteSegment()"
        >
          Yes! Delete
        </v-btn>
        <v-btn depressed tile :disabled="pageLoading" v-on:click="deleteDialog = false">
          No, Close
        </v-btn>
      </template>
    </Dialog>
  </v-sheet>
</template>

<script>
import CreateSegment from "@/view/module/marketing/CreateSegment";
import ValueTemplate from "@/view/components/ValueTemplate";
import Chip from "@/view/components/Chip";
import moment from "moment-timezone";
import { POST } from "@/core/services/store/request.module";
import Dialog from "@/view/components/Dialog";
import { GET_SEGMENTS } from "@/core/lib/marketing.lib";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
import { CHANGE_SEGMENTS_STATUS, DELETE_SEGMENTS } from "@/core/lib/marketing.lib";
import { isArray,filter,map } from "lodash";
export default {
  name: "customer-create",
  title: "Create Customer",
  components: { ValueTemplate,Chip, CreateSegment,Dialog },
  data() {
    return {
      pageLoading: false,
      generateSegmentDialog: false,
      deleteDialog: false,
      defaultColDefs: [],
      customThead: [],
      deleteText: null,
      deleteId: null,
      showingString: null,
      currentPage: 1,
      per_page: null,
      loadPagination: false,
      totalPages: null,
      deleteEndpoint: null,
      segmentList: [],
      summary: [],
    };
  },
  watch: {
    currentPage() {
      this.getSegments();
    },
    listingSearch() {
      this.getSegments();
    },
    
  },
  computed: {
    // 	product_list: () => {
    // 	return function (id) {
    // 		let _productList = cloneDeep(this.productList);
    // 		const newArray = filter(_productList, (row) => {
    // 			if (row.value != id) {
    // 				if (!find(this.line_items, (inner_row) => inner_row.product == row.id)) {
    // 					return row;
    // 				}
    // 			} else {
    // 				return row;
    // 			}
    // 		});
    // 		return newArray;
    // 	};
    // },
    is_array() {
      console.log("segmentList", this.segmentList.length);
      return isArray(this.segmentList);
    },
  },

  methods: {
   
   
    dragEnd() {
      const dragStartElement = this.employeeTableHeading[this.dragStartIndex];
      this.employeeTableHeading.splice(this.dragStartIndex, 1);
      this.employeeTableHeading.splice(this.dragOverIndex, 0, dragStartElement);
    },
    updateVisible() {
      const _this = this;

      for (let index = 0; index < _this.defaultColDefs.length; index++) {
        const element = _this.defaultColDefs[index];
        _this.defaultColDefs[index].visible = 0;
        if (_this.defaultColShow.includes(element.field)) {
          _this.defaultColDefs[index].visible = 1;
        }
      }
      // console.log(this.defaultColDefs);

      _this.$nextTick(() => {
        _this.$store
          .dispatch(POST, {
            url: "group",
            data: { tables: this.defaultColDefs },
          })
          .then((data) => {
            this.customThead = data;
            // console.log(data)
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.getSegments();
          });
      });
    },
    formatedateTimes(param) {
      return moment(param).format("DD/MM/YYYY hh:mm A");
    },
    goToSegmentDetail(id) {
      this.$router.push({
        name: "segment-detail",
        params: { id: id },
        query: { t: new Date().getTime() },
      });
    },

    async getSegments() {
      const _this = this;
      this.pageLoading = true;
      try {
        const { rows, showing_string, status_list, totalPages,theads } = await GET_SEGMENTS(
          this.currentPage,
          
          this.per_page
        );
        this.segmentList = rows;
        this.showingString = showing_string;
        this.totalPages = totalPages;
        this.summary = status_list;
        let thead = theads;
        this.defaultColDefs = thead;
        this.customThead = [];
        for (let index = 0; index < thead.length; index++) {
          let element = thead[index];
          if (element.visible == 1) {
            this.customThead.push(element);
          }
        }

        let t_out = filter(thead, (row) => Number(row.visible) == 1);
        this.defaultColShow = map(t_out, "field");

        console.log(this.customThead);

      } catch (error) {
        _this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.pageLoading = false;
        this.loadPagination = true;
      }
    },
    async segmentsDeleteSuccess() {
      await this.getSegments();
      this.deleteDialog = false;
    },
    async deleteSegment() {
      const _this = this;
      const params = {
        selected: [_this.deleteId],
      };
      this.pageLoading = true;
      try {
        await DELETE_SEGMENTS(params);
        _this.$store.commit(SET_MESSAGE, [
          { model: true, message: "Segment delete successfully." },
        ]);
        await this.getSegments();
        this.deleteDialog = false;
      } catch (error) {
        _this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.pageLoading = false;
      }
    },
    async deleteConfirm(name, id) {
      
      this.deleteText = name;
      this.deleteId = id;
      this.deleteDialog = true;
    },
    async changeSegmentStatus(status, id) {
      const _this = this;
      const params = {
        action: status == 1 ? "active" : "inactive",
        selected: [id],
      };
      this.pageLoading = true;
      try {
        await CHANGE_SEGMENTS_STATUS(params);
        _this.$store.commit(SET_MESSAGE, [
          { model: true, message: "Segment Status Change Successfully." },
        ]);
        await this.getSegments();
      } catch (error) {
        _this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.pageLoading = false;
      }
    },
  },
  async mounted() {
    await this.getSegments();
  },
};
</script>
